module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-react-bootstrap","short_name":"react-bootstrap","start_url":"/","background_color":"#20232a","theme_color":"#20232a","display":"minimal-ui","icon":"/opt/build/repo/src/assets/images/icon.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"678df2f69ae2dad131af18e6d09b2bd3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
